import { Loader } from "package:/components/elements/loader";
import { twMerge } from "tailwind-merge";
import { shouldUseOfflineData } from "package:/utils";
import { NuxtPicture } from "#components";
import { defineComponent, ref, useFlags, useNetwork, useOfflineStorage } from "#imports";

interface Props {
  class?: string;
  src?: string;
  width?: number;
  height?: number;
  sizes?: string;
  imgAttrs?: object;
  imgClass?: string;
  alt?: string;
  provider?: string;
  densities?: string;
  lazy?: boolean;
}

export const Picture = defineComponent(
  (props: Props) => {
    // offline images need to be decided here, because they are async
    const flags = useFlags();
    const src = ref(props.src);
    const storage = useOfflineStorage();
    const loaded = ref(false);

    if (flags.offline && shouldUseOfflineData() && props.src) {
      storage?.get(props.src).then((data) => {
        if (data) {
          const imageSrc = URL.createObjectURL(data.data);
          src.value = imageSrc;
        }
      });
    }

    return () => (
      <div class={twMerge("relative overflow-hidden", props.class)}>
        {!loaded.value && <Loader class="absolute" />}

        <NuxtPicture
          // TODO: fallback alt
          alt={props.alt}
          src={src.value}
          format="webp"
          class={twMerge( "contents", loaded.value ? "visible" : "invisible")}
          onLoad={() => {
            loaded.value = true;
          }}
          loading={props.lazy ? "lazy" : undefined}
          decoding={props.lazy ? "async" : undefined}
          width={props.width}
          height={props.height}
          provider="self"
          fit="crop"
          densities={props.densities ? props.densities : "x1 x2"}
          quality={65}
          sizes={props.sizes}
          imgAttrs={{ ...props.imgAttrs, class: twMerge("block object-cover", props.imgClass) }}
        />
      </div>
    );
  },
  {
    name: "Picture",
    props: [
      "class",
      "src",
      "width",
      "height",
      "sizes",
      "lazy",
      "imgAttrs",
      "imgClass",
      "alt",
      "densities",
      "provider",
    ],
  },
);
