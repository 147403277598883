import { useEnv } from "package:/composables/useEnv";
import type { ImageModifiers, ProviderGetImage } from "@nuxt/image";

function modifiersToParams(modifiers: Partial<ImageModifiers>) {
  const params = new URLSearchParams();
  modifiers.width && params.append("w", modifiers.width.toString());
  modifiers.height && params.append("h", modifiers.height.toString());
  modifiers.fit && params.append("fit", modifiers.fit);
  modifiers.format && params.append("fm", modifiers.format);
  modifiers.quality && params.append("q", modifiers.quality);
  return params;
}

export const getImage: ProviderGetImage = (src, { modifiers = {} } = {}) => {
  const env = useEnv();

  if (src.startsWith("/storage/assets")) {
    return {
      url: `${env.ASSETS_URL}${src}?${modifiersToParams(modifiers)}`,
    };
  }

  // fallback to original
  return {
    url: src,
  };
};
